
import { Options, Vue } from 'vue-class-component';
import { AfterLoginMandatoryDocumentClient, AuthClient } from '@/services/Services';
import { checkPassword } from '@/utils';
import * as OM from '@/model';
import SocialLogin from '../components/socialLogin.vue';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import MandatoryDocuments from '@/components/mandatoryDocuments.vue'

@Options({
    components: {
        SocialLogin,
        MandatoryDocuments
    }
})
export default class Login extends Vue {
    
    documents: OM.MandatoryDocumentAppVM[] = [];
    loading: boolean = false;
    languageCredential: OM.LanguageCredentialWithBirthDate = new OM.LanguageCredentialWithBirthDate();

    message: string = "";
    error: string = "";

    confirmationCode: string = "";
    useridentifier: string = "";

    created(){
        this.languageCredential.preferredCulture = store.state.preferredCulture;

        AfterLoginMandatoryDocumentClient.getRegistrationMustSign()
        .then( x => {
            this.documents = x;
        })
    }

    get canRegister() {
        return this.languageCredential.username && this.languageCredential.password && this.validPassword && this.languageCredential.name.trim() && this.languageCredential.surname.trim() && this.languageCredential.birthDate;
        // && this.conditions
    }

    get validPassword() {
        return checkPassword(this.languageCredential.password);
    }

    doRegister() {
        if(!this.canRegister)
            return;

        this.error = "";
        this.loading = true;
        AuthClient.register(this.languageCredential)
        .then(x => {
            this.languageCredential.birthDate = this.languageCredential.birthDate;

            StorageServices.setUserIdentifier(x.identifier);
            this.$router.push({
                path: "/confirmedCode",
                query: this.$route.query
            });
        })
        .catch(err => {
            this.error = err.message;

            setTimeout(() => {
                this.error = "";
            }, 3000);
        })
        .finally(() => this.loading = false)
    }

}
